import React from "react";
import PropTypes from "prop-types";

const Checked = ({ className }) => (
  <svg
    className={className}
    width="13"
    height="10"
    viewBox="0 0 13 10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.563 4.288L0 5.851l3.825 3.825c.45.45 1.187.428 1.609-.048l7.23-8.162L11.01 0 4.558 7.282 1.563 4.288z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
);

Checked.propTypes = {
  className: PropTypes.string
};

Checked.defaultProps = {
  className: ""
};

export default Checked;
