import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Image, MetaTitle, Section, Wysiwyg } from "../atoms";
import {
  colors,
  formatIndexNumber,
  makeAnchor,
  sizes,
  getSiteMetaData
} from "../utils";
import { blockShape } from "../utils/shapes";

const StyledTextMedia = styled(Section)`
  margin-top: 1px;
  background: ${props => (props.even ? colors.gray100 : colors.offwhite)};

  .column {
    max-width: 38rem;

    @media (min-width: ${sizes.desktop}) {
      max-width: none;
      width: 50%;
    }
  }

  .column:first-of-type {
    margin-bottom: 2rem;

    @media (min-width: ${sizes.desktop}) {
      margin-bottom: 0;
      padding-right: 7%;
    }
  }

  .section-name {
    margin-bottom: 1rem;
  }

  .heading {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 1.4;
    color: ${colors.black};
    margin-bottom: 2rem;
  }
`;

const LessonBlockTextMedia = ({ block, index }) => {
  const { title, heading, description, tommyMedia, ckMedia } = block;

  const { theme } = getSiteMetaData();
  const media = theme === "TH" ? tommyMedia : ckMedia;

  const even = index % 2 === 0;
  const sectionNumber = formatIndexNumber(index);
  const anchor = makeAnchor(title);

  return (
    <StyledTextMedia even={even} id={anchor}>
      <div className="column">
        <MetaTitle
          className="section-name"
          color="accent"
          sectionNumber={sectionNumber}
        >
          {title}
        </MetaTitle>
        <h2 className="heading">{heading}</h2>
        <Wysiwyg content={description} />
      </div>
      <div className="column">
        <Image borderRadius shadow image={media} useSrcSet />
      </div>
    </StyledTextMedia>
  );
};

LessonBlockTextMedia.propTypes = {
  block: blockShape.isRequired,
  index: PropTypes.number.isRequired
};

export default LessonBlockTextMedia;
