import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { InView } from "react-intersection-observer";

import { Wysiwyg } from "../atoms";
import ButtonShop from "./buttonShop";
import ButtonDone from "./buttonDone";

import { GameContext } from "../context/gameContext";
import { colors, sizes, easings, makeAnchor, getSiteMetaData } from "../utils";
import { lessonFinishedTitle } from "../utils/textStyles";
import {
  lessonShape,
  translationFuncShape,
  localeShape
} from "../utils/shapes";

const StyledLessonFinished = styled.div`
  padding: 0;

  @media (min-width: ${sizes.desktop}) {
    display: flex;
    flex-direction: row;
  }

  .content {
    padding: 2rem 1rem;

    @media (min-width: ${sizes.tablet}) {
      padding: 4rem;
    }

    @media (min-width: ${sizes.desktop}) {
      padding: 5rem 4% 5rem 14%;
      width: 67%;
    }

    @media (min-width: ${sizes.xldesktop}) {
      padding: 6rem 7% 6rem 25%;
    }

    @media (min-width: ${sizes.huge}) {
      padding-right: 10%;
    }
  }

  .heading {
    ${lessonFinishedTitle}
    margin-bottom: 1rem;
  }

  .heading .bold {
    margin-left: 0.4rem;
    font-weight: 800;
    color: ${({ theme }) =>
      theme.brand === "TH" ? colors.green : colors.greenCK};
  }

  .button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
  }

  .button-group a:first-of-type {
    margin-right: 1rem;
  }

  .icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6rem 0;

    @media (min-width: ${sizes.desktop}) {
      width: 33%;
      padding: 6rem 0;
    }
  }

  .icon-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) =>
      theme.brand === "TH" ? colors.green : colors.greenCK};
    transform: ${props => (props.inView ? `scaleX(1)` : `scaleX(0)`)};
    transform-origin: left;
    transition: transform 0.7s ${easings.default};
    z-index: -1;
  }

  .icon-circle {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: 11.25rem;
    height: 11.25rem;
    border-radius: 50%;
    background: ${colors.white};
    transform: ${props =>
      props.inView
        ? `translate(-50%, -50%) scale(1)`
        : `translate(-50%, -50%) scale(0)`};
    transform-origin: center;
    transition: transform 0.3s ${easings.default} 0.2s;
  }

  .icon-finished {
    width: 100px;
    height: 100px;
    z-index: 10;
    opacity: ${props => (props.inView ? 1 : 0)};
    transform-origin: bottom;
    transform: ${props =>
      props.inView
        ? `translate(0%, 0%) rotate(0deg)`
        : `translate(-15%, 30%) rotate(20deg)`};
    transition: opacity 0.4s ${easings.default} 0.15s,
      transform 0.7s ${easings.bounce} 0.15s;
  }

  .icon-items-line {
    fill: #445264;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    opacity: ${props => (props.inView ? 1 : 0)};
    transition: opacity 0.3s ${easings.default};
  }

  .icon-items-line.line-1 {
    transition-delay: 0.35s;
  }
  .icon-items-line.line-2 {
    transition-delay: 0.4s;
  }
  .icon-items-line.line-3 {
    transition-delay: 0.45s;
  }

  .icon-items-plus {
    fill: #445264;
    fill-rule: evenodd;
    clip-rule: evenodd;
    opacity: ${props => (props.inView ? 1 : 0)};
    transition: opacity 0.3s ${easings.default};
  }

  .icon-items-plus.plus-1 {
    transition-delay: 0.7s;
  }
  .icon-items-plus.plus-2 {
    transition-delay: 0.74s;
  }
`;

const LessonFinished = ({
  lesson,
  linkedTopic,
  id,
  t,
  pushUtagData,
  locale
}) => {
  const { isDone, onTaskDone } = useContext(GameContext);

  const {
    lessonfinishedtext,
    ckcustomshoplink = "",
    thcustomshoplink = ""
  } = lesson;

  const handleChange = inView => {
    if (inView && !isDone(id)) {
      onTaskDone(id);
      pushUtagData();
    }
  };

  const { theme } = getSiteMetaData();

  // Select shop url
  const baseShopUrl = theme === "TH" ? t("link-shop-th") : t("link-shop-ck");
  const customShopUrl = theme === "TH" ? thcustomshoplink : ckcustomshoplink;
  const shopUrl = customShopUrl.length > 5 ? customShopUrl : baseShopUrl;

  // Topic urls
  const langCode = `${locale.code.toLowerCase().substring(0, 2)}`;
  const topicUrl = `/${langCode}#${makeAnchor(linkedTopic)}`;

  const green = theme === "TH" ? colors.green : colors.greenCK;

  return (
    <InView
      threshold={0.8}
      triggerOnce
      onChange={inView => handleChange(inView)}
    >
      {({ inView, ref }) => (
        <StyledLessonFinished inView={inView} ref={ref}>
          <div className="content">
            <h2 className="heading">
              {t("lesson-finished-first")}
              <span className="bold">{t("lesson-finished-second")}</span>
            </h2>
            <Wysiwyg content={lessonfinishedtext && lessonfinishedtext.value} />
            <div className="button-group">
              <ButtonDone href={topicUrl} onClick={pushUtagData}>
                {t("lesson-finished-btn")}
              </ButtonDone>
              <ButtonShop href={shopUrl}>{t("nav-shop-button")}</ButtonShop>
            </div>
          </div>
          <div className="icon-wrapper">
            <div className="icon-background" />
            <div className="icon-circle" />
            <svg
              className="icon-finished"
              xmlns="http://www.w3.org/2000/svg"
              x="0"
              y="0"
              viewBox="0 0 100 100"
            >
              <path
                d="M68.97 52.1H57.01v-8.46c0-3.62-1.04-6.11-3.12-7.58-1.4-1-3.11-1.43-4.99-1.4-1.22.01-2.21.19-3.01.41-.54.18-.89.65-.89 1.22v9.47c0 3.39-1.79 6.03-4.71 7.99-1.52 1.02-2.55 1.47-4.37 2.12l-.67.24-.5-.49c-.64-.62-1.53-.98-2.46-.98h-7.42c-1.88 0-3.46 1.54-3.46 3.37v23.96c.06 1.93 1.66 3.49 3.55 3.49h7.42c.7 0 1.32-.21 1.97-.63l.72-.47.64.56a7.619 7.619 0 005.09 1.92h22.59c5.18 0 8.46-2.68 9.06-7.3l4.15-19.33V59.56c0-4.12-3.41-7.46-7.63-7.46zM33.1 82.3h-8.39V58.02h8.39V82.3zm39.87-22.59L68.8 79.06v.08c-.17 1.14-.5 4.08-5.34 4.08H40.87c-2.17 0-3.92-1.71-3.92-3.84V59.47c1.33-.41 11.84-3.84 11.84-13.63v-7.43c1-.08 2.17.08 3 .65 1.08.73 1.58 2.29 1.58 4.57v9.79c0 1.31 1.08 2.37 2.42 2.37h13.26c2.17 0 3.92 1.71 3.92 3.84v.08z"
                fill={green}
              />
              <path
                className="icon-items-line line-1"
                d="M50.5 13.33c-.83 0-1.5.67-1.5 1.5v10.74c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V14.83c0-.82-.67-1.5-1.5-1.5z"
              />
              <path
                className="icon-items-line line-2"
                d="M65.82 22.08c-.69-.46-1.62-.26-2.08.43l-2.5 3.8c-.46.69-.26 1.62.43 2.08.25.17.54.25.82.25.49 0 .97-.24 1.25-.68l2.5-3.8c.47-.69.28-1.62-.42-2.08z"
              />
              <path
                className="icon-items-line line-3"
                d="M40.54 26.37l-2.25-3.8a1.5 1.5 0 00-2.05-.53 1.5 1.5 0 00-.53 2.05l2.25 3.8c.28.47.78.74 1.29.74.26 0 .52-.07.76-.21.72-.42.95-1.34.53-2.05z"
              />
              <path
                className="icon-items-plus plus-1"
                d="M88.7 36.08c0-.71-2.16-1.31-5.05-1.46-.16-2.61-.75-4.54-1.45-4.54s-1.29 1.93-1.45 4.54c-2.89.15-5.05.75-5.05 1.46s2.16 1.31 5.05 1.46c.16 2.61.75 4.54 1.45 4.54s1.29-1.93 1.45-4.54c2.89-.15 5.05-.74 5.05-1.46z"
              />
              <path
                className="icon-items-plus plus-2"
                d="M20.7 45.58c0-.71-2.16-1.31-5.05-1.46-.16-2.61-.75-4.54-1.45-4.54s-1.29 1.93-1.45 4.54c-2.89.15-5.05.75-5.05 1.46s2.16 1.31 5.05 1.46c.16 2.61.75 4.54 1.45 4.54s1.29-1.93 1.45-4.54c2.89-.15 5.05-.74 5.05-1.46z"
              />
            </svg>
          </div>
        </StyledLessonFinished>
      )}
    </InView>
  );
};

LessonFinished.propTypes = {
  lesson: lessonShape.isRequired,
  linkedTopic: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  t: translationFuncShape.isRequired,
  pushUtagData: PropTypes.func.isRequired,
  locale: localeShape.isRequired
};

export default LessonFinished;
