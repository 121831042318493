import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import LessonCard from "./lessonCard";
import { colors, sizes } from "../utils";
import {
  lessonShape,
  localeShape,
  translationFuncShape
} from "../utils/shapes";
import { lessonNextTitle } from "../utils/textStyles";

const StyledLessonNext = styled.div`
  padding: 2rem;
  background: ${colors.gray100};

  @media (min-width: ${sizes.desktop}) {
    padding: 8rem 4% 6rem 14%;
  }

  @media (min-width: ${sizes.xldesktop}) {
    padding: 8rem 10% 8rem 25%;
  }

  .heading {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 2rem;

    @media (min-width: ${sizes.desktop}) {
      margin-bottom: 4rem;
    }
  }

  .heading h2 {
    ${lessonNextTitle}
    color: ${colors.accent};
    margin-right: 2rem;

    @media (min-width: ${sizes.desktop}) {
      font-size: 2.8rem;
    }
  }

  .heading .line {
    display: block;
    flex-grow: 2;
    height: 1px;
    background: ${colors.gray200};
  }

  .list-content {
    @media (min-width: ${sizes.tablet}) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }

    @media (min-width: ${sizes.desktop}) {
      width: 105%;
      margin-left: -5%;
    }
  }

  .list-content li {
    width: 90%;
    max-width: 22.5rem;
    margin: 0 auto 20%;

    @media (min-width: ${sizes.tablet}) {
      width: 45%;
      max-width: unset;
      margin: 0rem 1rem 15%;
    }

    @media (min-width: ${sizes.desktop}) {
      width: 29.6788%;
      margin: 0 1.6611% 5rem;
    }

    @media (min-width: ${sizes.xldesktop}) {
      margin-bottom: 8vw;
    }

    @media (min-width: ${sizes.huge}) {
      margin-bottom: 10vw;
    }
  }
`;

const LessonNext = ({ lessons, locale, t }) => {
  return (
    <StyledLessonNext>
      <div className="heading">
        <h2>{t("lesson-next")}</h2> <span className="line" />
      </div>
      <ul className="list-content">
        {lessons.length > 0 &&
          lessons.map(lesson => {
            const { id } = lesson;
            return (
              <li key={id}>
                <LessonCard lesson={lesson} locale={locale} t={t} />
              </li>
            );
          })}
      </ul>
    </StyledLessonNext>
  );
};

LessonNext.propTypes = {
  lessons: PropTypes.arrayOf(lessonShape).isRequired,
  locale: localeShape.isRequired,
  t: translationFuncShape.isRequired
};

export default LessonNext;
