/* global window */
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

import LessonHeader from "../components/lessonHeader";
import LessonBlockTextMedia from "../components/lessonBlockTextMedia";
import LessonBlockFullMedia from "../components/lessonBlockFullMedia";
import LessonFinished from "../components/lessonFinished";
import LessonNext from "../components/lessonNext";
import LocalNavigation from "../containers/localNavigation";

import { makeAnchor, pushTeliumView } from "../utils";
import { GlobalContext } from "../context/globalContext";
import {
  lessonShape,
  localeShape,
  topicShape,
  translationIndexShape
} from "../utils/shapes";
import getTranslationFn from "../utils/translations";
import { GameContext } from "../context/gameContext";

const blockPicker = type => {
  const mediaType = type.split(":")[1];
  switch (mediaType) {
    case "textandmedia":
      return LessonBlockTextMedia;
    case "largemedia":
      return LessonBlockFullMedia;
    default:
      return null;
  }
};

const Lesson = ({
  pageContext: {
    page: lesson,
    topic,
    nextTopic,
    topicLessons,
    locale,
    translationIndex
  }
}) => {
  const { state } = useContext(GlobalContext);
  const [ref, inView] = useInView();
  const t = getTranslationFn(translationIndex);

  const { title, introduction, duration, introImage, lessonblocks } = lesson;
  const { title: topicTitle } = topic;
  const nextTopicTitle = nextTopic ? nextTopic.title : "";

  // Page information for analytics & globalContext
  const pageData = {
    page_title: title,
    page_type: "lesson",
    page_locale: locale.code
  };

  // Local Nav sections
  const blocksOrEmpty = lessonblocks || [];
  const blocks = Array.isArray(blocksOrEmpty) ? blocksOrEmpty : [lessonblocks];

  const blockSections = blocks.map(block => {
    const blockTitle = block.title;
    const anchoredTitle = makeAnchor(blockTitle);

    return {
      uniqueName: anchoredTitle,
      label: blockTitle,
      path: `#${anchoredTitle}`
    };
  });

  const sections = [
    {
      uniqueName: "intro",
      label: t("lesson-intro"),
      path: "#intro"
    },
    ...blockSections
  ];

  const { isDone } = useContext(GameContext);
  const orderedLessonIds = topicLessons.map(({ id }) => id);
  const { id: currentLessonId } = lesson;

  const currentLessonIndex = orderedLessonIds.indexOf(currentLessonId);
  const nextLessons = topicLessons
    .slice(currentLessonIndex + 1)
    .filter(l => !isDone(l.id));
  const yetToCompleteLessons = nextLessons.concat(
    topicLessons.slice(0, currentLessonIndex).filter(l => !isDone(l.id))
  );

  const pushUtagData = () => {
    if (window.utag) {
      window.utag.link({
        tealium_event: "lesson_finished",
        lesson_title: title,
        lesson_id: currentLessonId,
        lesson_topic: topicTitle,
        ...state.pageData
      });
    }
  };

  useEffect(() => {
    pushTeliumView({
      tealium_event: "page_view",
      ...pageData
    });

    state.updateState({ page: { ...pageData } });
  }, []);

  return (
    <>
      <LocalNavigation sections={sections} isVisible={!inView} hue="light" />
      <LessonHeader
        name={title}
        description={introduction}
        topic={topicTitle}
        duration={duration}
        image={introImage}
        t={t}
      />
      {blocks &&
        blocks.map((block, i) => {
          const ChosenBlock = blockPicker(block.type);
          const key = block.title;

          return <ChosenBlock key={key} block={block} index={i} />;
        })}
      <div ref={ref}>
        <LessonFinished
          lesson={lesson}
          linkedTopic={
            yetToCompleteLessons.length === 0 ? nextTopicTitle : topicTitle
          }
          id={lesson.id}
          t={t}
          pushUtagData={pushUtagData}
          locale={locale}
        />
        {yetToCompleteLessons.length > 0 && (
          <LessonNext lessons={yetToCompleteLessons} locale={locale} t={t} />
        )}
      </div>
    </>
  );
};

Lesson.propTypes = {
  pageContext: PropTypes.shape({
    page: lessonShape.isRequired,
    topic: topicShape.isRequired,
    nextTopic: topicShape.isRequired,
    topicLessons: PropTypes.arrayOf(lessonShape),
    locale: localeShape.isRequired,
    translationIndex: translationIndexShape.isRequired
  }).isRequired
};

export default Lesson;
