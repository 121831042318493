import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Image, MetaTitle, Section, Wysiwyg } from "../atoms";
import {
  colors,
  formatIndexNumber,
  makeAnchor,
  sizes,
  getSiteMetaData
} from "../utils";
import { blockShape } from "../utils/shapes";
import { lessonStepTitle } from "../utils/textStyles";

const StyledFullMedia = styled(Section)`
  background: ${props => (props.even ? colors.gray100 : colors.offwhite)};

  .section-name {
    margin-bottom: 1rem;
  }

  .heading {
    ${lessonStepTitle}
    margin-bottom: 1rem;
    color: ${colors.black};

    @media (min-width: ${sizes.desktop}) {
      max-width: 68%;
    }
  }

  .description {
    @media (min-width: ${sizes.desktop}) {
      max-width: 83%;
    }
  }

  .media {
    margin-top: 2rem;

    @media (min-width: ${sizes.desktop}) {
      width: 105%;
      margin: 3rem 0 0 -5%;
    }
  }
`;

const LessonBlockFullMedia = ({ block, index }) => {
  const { heading, title, description, tommyMedia, ckMedia } = block;

  const { theme } = getSiteMetaData();
  const media = theme === "TH" ? tommyMedia : ckMedia;

  const even = index % 2 === 0;
  const sectionNumber = formatIndexNumber(index);
  const anchor = makeAnchor(title);

  return (
    <StyledFullMedia even={even} id={anchor}>
      <div className="column">
        <MetaTitle
          className="section-name"
          color="accent"
          sectionNumber={sectionNumber}
        >
          {title}
        </MetaTitle>
        <h2 className="heading">{heading}</h2>
        <Wysiwyg className="description" content={description} />
        <div className="media">
          <Image borderRadius shadow image={media} useSrcSet />
        </div>
      </div>
    </StyledFullMedia>
  );
};

LessonBlockFullMedia.propTypes = {
  block: blockShape.isRequired,
  index: PropTypes.number.isRequired
};

export default LessonBlockFullMedia;
