import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, sizes, easings } from "../utils";
import Checked from "../atoms/icons/checked";
import { CTAs } from "../utils/textStyles";

const StyledButtonDone = styled(Link)`
  ${CTAs}
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2.4rem 1rem 1rem;
  color: ${({ theme }) =>
    theme.brand === "TH" ? colors.green : colors.greenCK};
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  border: 1px solid
    ${({ theme }) => (theme.brand === "TH" ? colors.green : colors.greenCK)};
  border-radius: 2px;
  transition: color 0.3s ${easings.default}, background 0.3s ${easings.default};

  @media (min-width: ${sizes.desktop}) {
    padding: 1.3rem 1.2rem 1.4rem 1.2rem;
  }

  @media (min-width: ${sizes.xxldesktop}) {
    padding: 1.5rem;
  }

  &:hover {
    color: ${colors.white};
    background: ${({ theme }) =>
      theme.brand === "TH" ? colors.green : colors.greenCK};
  }

  > span {
    display: block;
  }

  .label {
    margin-right: 0.6rem;
  }

  .done-icon-wrapper {
    position: relative;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: ${({ theme }) =>
      theme.brand === "TH" ? colors.green : colors.greenCK};
    transition: background 0.3s ${easings.default};

    @media (min-width: ${sizes.desktop}) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &:hover .done-icon-wrapper {
    background: ${colors.white};
  }

  .done-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.8125rem;
    height: 0.625rem;
    path {
      fill: ${colors.white};
      transition: fill 0.3s ${easings.default};
    }
  }

  &:hover .done-icon path {
    fill: ${({ theme }) =>
      theme.brand === "TH" ? colors.green : colors.greenCK};
  }
`;

const ButtonDone = ({ children, href, onClick }) => (
  <StyledButtonDone to={href} onClick={onClick}>
    <span className="label">{children}</span>
    <span className="done-icon-wrapper">
      <Checked className="done-icon" />
    </span>
  </StyledButtonDone>
);

ButtonDone.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func
};

ButtonDone.defaultProps = {
  href: "",
  onClick: () => {}
};

export default ButtonDone;
